import {useState,useEffect,useRef} from 'react';
import DurationControls from './Components/DurationControls.js';
import MicPermissions from './Components/MicPermissions.js';
import useAudio from './utils/useAudio.js';
import Recordings from './Components/Recordings.js';
import uuidv4 from './utils/uuidv4.js';
import './App.css';



function App() {
  const [playing, toggle, audio] = useAudio('./sing_along.m4a');
  const chartRef = useRef(null);
  const audioStream = useRef(null);
  const [latestTrack,setLatestTrack] = useState(null)
  const [permissionGranted,setPermissionGranted] = useState(false);
  const [isRecordingNow,setIsRecordingNow] = useState(false);
  const sessionId = uuidv4();
  const blobs = useRef({});

  const tracks = {
    hbd1:{start:101,end:125,name: 'Happy Birthday - Sing'},
    hbd2:{start:160,end:182,name: 'Happy Birthday - Hum'},
    welcome1:{start:236,end:239, name: 'Welcome - 1'},
    welcome2:{start:255,end:258, name: 'Welcome - 2'},
    cheer:{start:330,end:353, name: 'Cheer Loud!'}
  }
  
  return (
    <div id="App">
      {
        permissionGranted ? 
        <div id="notes">
          Please use headphones to ensure the recording is not picking up background noise from the instruction audio
        </div>
        : 
        null
      }
      
      <MicPermissions audioStream={audioStream} setPermissionGranted={setPermissionGranted} />
      <svg id="bins" ref={chartRef}></svg>
      <DurationControls 
        audio={audio} 
        playing={playing} 
        toggle={toggle} 
        chartRef={chartRef} 
        audioStream={audioStream} 
        blobs={blobs}
        tracks={tracks}
        setLatestTrack={setLatestTrack}
        setIsRecordingNow={setIsRecordingNow}
      />
      <Recordings
        blobs={blobs}
        tracks={tracks}
        sessionId={sessionId}
        instructionAudio={audio}
        instructionAudioPlaying={playing}
        instructionAudioToggle={toggle}
        isRecordingNow={isRecordingNow}
      />
    </div>
  );
}

export default App;
