import {useState,useEffect,useRef} from 'react';
import PlayPauseButton from './PlayPauseButton.js';
import AudioRecorder from '../utils/AudioRecorder.js';


function DurationControls(props){
	const [currentTime,setCurrentTime] = useState(0);
	const [duration,setDuration] = useState(0);
	const timer = useRef(undefined);

	const [isRecording,setIsRecording] = useState(false);
	const isRecordingRef = useRef(false);
	const [currentTrack,setCurrentTrack] = useState(null);
	
	const recordingFinished = (trackName) => {
		props.setLatestTrack(trackName);
	}
	const getTime = (seconds,type) => {
		let mins = Math.floor(seconds / 60);
		let secs = Math.floor(seconds % 60);
		if(mins == 0 && secs == 0 && type == 'duration'){
			return '--:--';
		}
		return (mins < 10 ? '0'+mins : mins) + ':' + (secs < 10 ? '0'+secs : secs);

	}
	const checkRecordingState = () => {
		const now = props.audio.currentTime;
		let shouldRecord = false;
		let activeTrack;
		Object.keys(props.tracks).map(t=>{
			if(props.tracks[t].start <= now && props.tracks[t].end >= now){
				shouldRecord = true;
				activeTrack = t;
			}
		})
		if(!isRecordingRef.current && shouldRecord){
			//start the recording
			console.log('set is recording',activeTrack);
			console.log('track details',props.tracks[activeTrack])
			setCurrentTrack(activeTrack);
			setIsRecording(true);
			props.setIsRecordingNow(true)
			isRecordingRef.current = true;
		}
		if(isRecordingRef.current && !shouldRecord){
			console.log('set stop recording DurationControls')
			//we just finished a recording, trigger save event
			setIsRecording(false);
			props.setIsRecordingNow(false);
			isRecordingRef.current = false;
		}
	}
	useEffect(()=>{
		if(timer.current){
			clearInterval(timer.current);
			timer.current = false;
		}
		if(props.playing){
			setCurrentTime(props.audio.currentTime);
			setDuration(props.audio.duration);
			timer.current = setInterval(()=>{
				setCurrentTime(props.audio.currentTime);
				setDuration(props.audio.duration);
				checkRecordingState()
			},1000);

		}
		else{
			if(timer.current){
				clearInterval(timer.current);
				timer.current = false;
			}
		}
	},[props.playing]);

	return (
		<div className="durationControls">
			<PlayPauseButton isPlaying={props.playing} isRecording={isRecording} toggle={props.toggle} chartRef={props.chartRef} />
			{
				duration > 0 ? 
					<div className="duration">
						{getTime(currentTime,'current')} | {getTime(duration,'duration')}
					</div>
				:
					null
			}
			
			<AudioRecorder 
				audioStream={props.audioStream} 
				isRecording={isRecording} 
				currentTrack={currentTrack} 
				setCurrentTrack={setCurrentTrack} 
				blobs={props.blobs}
				recordingFinished={recordingFinished}
				tracks={props.tracks}
				instructionAudio={props.audio}

			/>
			
		</div>
	)
}

export default DurationControls;