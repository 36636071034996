import {useState,useRef,useEffect} from 'react'
import '../css/Recordings.css';
import axios from 'axios';

export default function Recordings(props){
	console.log('render recordings',props);
	const [playingTrack,setPlayingTrack] = useState({});
	const [saveLabel,setSaveLabel] = useState('SEND');
	const [hasSent,setHasSent] = useState(false);
	function playbackTrack(key){
		//if we are currently recording, do nothing..
		if(props.isRecordingNow){
			return false;
		}
		//pause instructions if playing
		if(props.instructionAudioPlaying){
			props.instructionAudioToggle();

		}
		const blob = props.blobs.current[key];
		const url = URL.createObjectURL(blob);
		const audio = new Audio(url)
		if(playingTrack && playingTrack.audio){
			if(playingTrack.key == key){
				console.log('is same key, is paused now?',playingTrack.audio.paused,playingTrack.audio.currentTime)

				//paused track?
				if(playingTrack.audio.paused){
					console.log('is same key, is paused?',playingTrack.audio.paused)
					playingTrack.audio.play();
					
					setPlayingTrack({audio:playingTrack.audio,key:playingTrack.key});
					return;
				}
				playingTrack.audio.pause();
				setPlayingTrack({audio:playingTrack.audio,key:playingTrack.key});
				return;
			}
			playingTrack.audio.pause(); //its a different track, pause the current one

		}
		console.log('audio',audio);
		setPlayingTrack({audio,key});
		audio.play();
		audio.addEventListener('ended', () => {
			console.log('onstop')
			setPlayingTrack({audio,key});
		})
	}
	function sendData(){
		console.log('has sent',hasSent);
		if(hasSent){
			console.log('already sent');
			return false;
		}
		const form = new FormData();
	  Object.keys(props.blobs.current).map(key=>{
      form.append('track',props.blobs.current[key],props.sessionId+'.'+key+'.wav');
    });
    setSaveLabel('SENDING...');
    axios.post('https://orbitpavillion.com/audio', form).then(data=>{
    	console.log('axios response',data);
    	setHasSent(true);
    	setSaveLabel('SENT, THANKS!')
    }).catch(e=>{
    	setSaveLabel('ERROR')
    	setHasSent(false);
    	console.log('error sending',e);
    })
	}
	console.log('props blobs',props.blobs.current)
	return (
		<div className="recordings">
			<div className="trackList">
				{Object.keys(props.blobs.current).map(key=>{
					
					return <div key={key} id={`track_${key}`} className={props.isRecordingNow ? "track isRecording" : "track"} onClick={()=>{ playbackTrack(key) } }>
						<span className="checkbox">
							<i className="fa-solid fa-check"></i>
						</span>
						{props.tracks[key].name}
						<span className="playback">
							{
							playingTrack ? 
								playingTrack.key === key ? 
									playingTrack.audio.paused ? 
									<i className="fa-solid fa-play"></i>
									:
									<i className="fa-solid fa-pause"></i>
								:
								<i className="fa-solid fa-play"></i>
							:
							<i className="fa-solid fa-play"></i>
							}

						</span>
					</div>
				})}
			</div>
			{
				Object.keys(props.blobs.current).length == Object.keys(props.tracks).length ? 
				<div className="save" onClick={sendData}>{saveLabel}</div> : 
				null
			}
		</div>
	)
}