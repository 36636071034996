import '../css/PlayPauseButton.css';

function PlayPauseButton(props) {
  function buttonClick(){
    if(props.isRecording){
      console.log('is recording, do nothing');
    }
    else{
      props.toggle()
    }
  }
  return (
    <div className="playPauseButton" onClick={()=>{buttonClick()}}>
      {
        props.isRecording ? 
          <i className="fa-solid fa-circle recording pulse"></i>
        :
        (
          props.isPlaying ? 
          <i className="fa-solid fa-pause"></i> : 
          <i className="fa-solid fa-play"></i>
        )
      }
    </div>
  );
}

export default PlayPauseButton;